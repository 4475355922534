/* eslint-disable import/prefer-default-export */
import React from "react";
import { Chapter } from "types/chapter";

import chapter1Image from "images/chapter1.jpg";
import chapter2Image from "images/chapter2.jpg";
import chapter3Image from "images/chapter3.jpg";
import chapter4Image from "images/chapter4.jpg";
import chapter5Image from "images/chapter5.png";

export const chapters: Chapter[] = [
  {
    id: 0,
    image: chapter1Image,
    chapterNumber: 1,
    title: "Start of the Adventure",
    description:
      "The new AR Game on a real world map with a different Social-Fi and Game-Fi mechanics",
    date: "21 mar",
    status: "available",
    text: (
      <>
        It was the most ordinary spring day. I was sitting in the park playing
        my favorite mobile arcade game. The week of the same and boring quests
        was ending; all I needed was a little time to gather resources. <br />
        <br />
        Well, not just &quot;a little&quot;... 2 more hours. All for the sake of
        exploring a new location with top equipment, the best one available at
        my level. Damn, I was just waiting for this.
        <br />
        <br />
        — Wow!
        <br />
        — What is this?
        <br />
        — Mom, look at these things in the sky!
        <br />
        <br />
        What is the reason they are all yelling about? My phone almost died. Oh
        well, damn! I shift my gaze from the phone screen to the city, and… What
        the hell is this?
      </>
    ),
  },
  {
    id: 1,
    image: chapter2Image,
    chapterNumber: 2,
    title: "Path to Reward",
    description:
      "The new AR Game on a real world map with a different Social-Fi and Game-Fi mechanics",
    date: "28 mar",
    status: "available",
    link: "https://mint.mytrc.me/",
    linkText: "Sign up",
    text: (
      <>
        I stared blankly at the marks hanging in the air while my brain tried to
        find a logical explanation. Aliens? Government experiments? Or
        hallucinations? <br />
        <br />
        — I think I heard something about it, - said the girl standing beside
        me, returning me to reality. - Hold on a minute...
        <br />
        <br />
        She quickly started typing something on her phone.
        <br />
        <br />
        — Here, it says that the marks lead to secret boxes. But you can’t open
        the boxes alone yourself. You need to register, assemble a team of four
        people and be the first to get to the coordinates. What do you say if we
        unite? Together we&apos;ll quickly find two more people for our team.
        <br />
        <br />
        I don&apos;t have much choice: to drop it and go home or trust a
        stranger and get involved in the hell knows what. But she said
        &quot;secret boxes&quot;... After all, what do I lose?
        <br />
        <br />
        — OK, you&apos;ve convinced me. I hope it will be interesting.
        <br />
        <br />
        Do me a favor, pls, and sign me up using the button &quot;SIGN UP&quot;.
        As I said, my battery is already dead...
      </>
    ),
  },
  {
    id: 2,
    image: chapter3Image,
    chapterNumber: 3,
    title: "Dreamteam Gets the Pass",
    description:
      "The new AR Game on a real world map with a different Social-Fi and Game-Fi mechanics",
    status: "available",
    date: "11 apr",
    link: "https://mint.mytrc.me/",
    linkText: "Mint Pass",
    text: (
      <>
        In a nutshell, we assembled a team.
        <br /> <br />
        I was fortunate to notice a young couple a little further down the path.
        They were discussing the tags loudly while looking at their phones, so I
        just walked over and asked:
        <br /> <br />
        — Are you also trying to get the coordinates of the box?
        <br /> <br />
        They both nodded.
        <br /> <br />
        — We need four team members; I recommend joining forces with that girl
        and I.
        <br /> <br />
        They looked at each other. The guy frowned and looked at the screen of
        his phone:
        <br /> <br />
        — Judging by the number of teams, time is running out. We&apos;re up for
        it!
        <br /> <br />
        His girlfriend nodded in agreement.
        <br /> <br />
        — Do we have a deal? - asked the girl coming up to them.
        <br /> <br />
        — Then I&apos;m getting the coordinates of the closest box... You will
        lead the team. She pointed at me.
        <br /> <br />
        — Why me?
        <br /> <br />
        — I&apos;ll keep track of the coordinates. I need someone leading me, so
        I don&apos;t run into a tree or something else while texting. Don&apos;t
        stop! Gotta get there faster than the rest, and the number of teams is
        constantly increasing.
        <br /> <br />
        Of course, why give me the option? Ok, take your NFT Pass now;
        you&apos;ll need it for further participation and to receive the reward
        coordinates. Follow me by pressing the &quot;Mint&quot; button.
      </>
    ),
  },
  {
    id: 3,
    image: chapter4Image,
    chapterNumber: 4,
    title: "Only One Winner",
    description:
      "The new AR Game on a real world map with a different Social-Fi and Game-Fi mechanics",
    status: "available",
    date: "13 apr",
    link: "https://reward.mytrc.me/",
    linkText: "Get Reward",
    text: (
      <>
        Thorns are clinging to my clothes, branches are whipping my face, while
        I am trying in vain to clear the way for the team.
        <br /> <br />
        — Remind me, why the hell are we going through the most densely
        overgrown part of the park?
        <br /> <br />
        — There is no time to get around, answered the girl. Behind this bush to
        the right.
        <br /> <br />
        Obediently I turn and push back a huge spruce branch with a faint hope
        that our treasure is behind it, but instead, I run against someone
        else&apos;s displeased face. Damn!
        <br /> <br />
        — Don&apos;t say you&apos;re on the mark, too, said the face. Three more
        flushed, panting faces appeared behind him. As if it is not obvious that
        there is only one mark and one reward on our way.
        <br /> <br />
        — I will not say it.
        <br /> <br />
        There is no need to waste time proving that we will be the first to
        reach the goal. So I just rushed forward. But we must give credit to my
        team: I hear they didn&apos;t get lost and are going for it.
        <br /> <br />
        We must not give up.
        <br /> <br />
        It looks like the bushes are thinning...
        <br /> <br />
        That&apos;s right, here&apos;s the mark! A bit more!
        <br /> <br />
        Press the button and grab the treasure!
      </>
    ),
  },
  {
    id: 4,
    image: chapter5Image,
    chapterNumber: 5,
    title: "Hello New World",
    description:
      "The new AR Game on a real world map with a different Social-Fi and Game-Fi mechanics",
    status: "soon",
    link: "https://mint.mytrc.me/",
    linkText: "Get Started",
    text: (
      <>
        Great! We did it! I didn&apos;t even think I&apos;d like it so much.
        <br /> <br />
        — Hey, there&apos;s something else here,&quot; the girl found some notes
        on the ground under the melted mark and began to study them.
        <br /> <br />
        — What do you mean?
        <br /> <br />
        — It looks like clues to the next mark!
        <br /> <br />
        She turned to me and smiled broadly. Than she sad:
        <br /> <br />
        — Looks like this is just the beginning. Ready to continue the
        adventure?
        <br /> <br />
        Actually, I didn&apos;t expect a sequel. Although… It was fun. And you
        can get to know the guys better.
        <br /> <br />
        — What&apos;s there to think about? Let&apos;s go!
        <br /> <br />
        If you are also ready for adventures in reality, press the button and go
        with us!
        <br /> <br />
      </>
    ),
  },
];
